import React from 'react'

function HomeSec() {
    return (
        <>
            {/* Section 1 start */}
            <section className="hero-section position-relative">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 position-relative">
                            <img className="vector" src="../image/png/vector.png" alt="" />
                            <img
                                className="hero-img"
                                src="../image/png/hero-img.png"
                                alt=""
                            />
                            <img
                                className="overlay"
                                src="../image/png/overlay.png"
                                alt=""
                            />
                        </div>
                        <div className="col-12 col-md-6 hero-right-text">
                            <h2>Rechtsanwalt</h2>
                            <h1>Dr. Beisinghoff </h1>
                            <h2>präsentiert Unicorso</h2>
                            <p>
                                also Kurse über den Datenschutz und über Compliance für Schulen
                                und auch für Unternehmen.
                            </p>
                        </div>
                    </div>
                </div>
                {/* <img className="hero-bg" src="../image/png/hero-bg.png" alt="" /> */}
            </section>
            {/* Section 1 end */}
        </>
    )
}

export default HomeSec
