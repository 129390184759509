import React from 'react'

function Section3() {
    return (
        <>
            {/* Section 3 start */}
            <section className="section-top-100">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6 text-box-right">
                            <h2>Wussten Sie?</h2>
                            <p>Paar Facts über Datenschutz und Compliance</p>
                            <ul className="wussten-list p-0">
                                <li>
                                    <img src="../image/png/right.png" alt="" />
                                    <span>
                                        Wussten Sie, dass sich WhatsApp in seinen AGB von Ihnen die
                                        gebührenfreie und übertragbare Lizenz zur Nutzung aller Ihrer
                                        Nachrichten inklusive Bilder einräumen lässt und davon auch
                                        Gebrauch macht?
                                    </span>
                                </li>
                                <li>
                                    <img src="../image/png/right.png" alt="" />
                                    <span>
                                        Wussten Sie, dass Sie als Unternehmen die Schufa-Scores nicht
                                        maßgeblich nutzen dürfen?
                                    </span>
                                </li>
                                <li>
                                    <img src="../image/png/right.png" alt="" />
                                    <span>
                                        Wussten Sie, dass viele Apps und Software-Produkte ihre Daten
                                        den Geheimdiensten zuspielen?
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-6 ps-3 ps-lg-5">
                            <img
                                src="../image/png/wussten.png"
                                className="wussten-img"
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>
            {/* Section 3 end */}
        </>
    )
}

export default Section3