import React from 'react'
import { Link } from 'react-router-dom'

function Footer(props) {
    const { name } = props;

    // companyName="Unicorso UG (in Gründung)"

    return (
        <>
            <footer>
                <div className="container">
                    <div className="row justify-content-between text-center text-md-start g-4">
                        <Link to='/privacy-policy' className="col-12 col-md-8 text-decoration-none">
                            <Link to='/privacy-policy' className='footer-title text-decoration-none'>Datenschutzerklärung</Link>
                            <p className='footer-title-description mt-3'>
                                Wir haben hier keine Cookies auf der Webseite. Wenn Sie mit mir kommunizieren dann speichere ich Ihre Email-Daten und ich bin verpflichtet etwa Rechnungsdaten über einen Zeitraum von etwa 10 Jahren aufzubewahren. Weitere Infos finden Sie auf der <Link to='/privacy-policy' className='footer-title-description text-decoration-none'>Datenschutzerklärung</Link> hier.
                            </p>
                        </Link>
                        <div className="col-12 col-md-4 ps-lg-5">
                            <p className='d-flex justify-content-center justify-content-md-start gap-2 footer-title'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M4.252 10C4.08416 10.6534 3.99949 11.3254 4 12C4 12.69 4.088 13.36 4.252 14H7.1C6.96518 12.6701 6.96518 11.3299 7.1 10H4.252ZM5.07 8H7.416C7.682 6.783 8.066 5.693 8.537 4.786C7.08518 5.48542 5.87722 6.60523 5.07 8ZM19.748 10H16.9C17.0348 11.3299 17.0348 12.6701 16.9 14H19.748C20.0847 12.6879 20.0847 11.3121 19.748 10ZM18.93 8C18.1228 6.60523 16.9148 5.48542 15.463 4.786C15.935 5.693 16.318 6.783 16.584 8H18.93ZM9.112 10C9.03708 10.664 8.99968 11.3318 9 12C9 12.685 9.038 13.355 9.112 14H14.888C15.0386 12.6709 15.0386 11.3291 14.888 10H9.112ZM9.47 8H14.53C14.348 7.2483 14.0855 6.51841 13.747 5.823C13.119 4.568 12.447 4 12 4C11.552 4 10.881 4.568 10.253 5.823C9.938 6.455 9.673 7.19 9.47 8ZM5.07 16C5.87722 17.3948 7.08518 18.5146 8.537 19.214C8.065 18.307 7.682 17.217 7.416 16H5.07ZM18.93 16H16.584C16.318 17.217 15.934 18.307 15.463 19.214C16.9148 18.5146 18.1228 17.3948 18.93 16ZM9.47 16C9.673 16.81 9.938 17.545 10.253 18.177C10.881 19.432 11.553 20 12 20C12.448 20 13.119 19.432 13.747 18.177C14.062 17.545 14.327 16.81 14.53 16H9.47ZM12 22C6.477 22 2 17.523 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22Z" fill="white" fill-opacity="0.9" />
                                </svg>
                                Impressum
                            </p>
                            {/* <p>{name}</p> */}
                            <p>unicorso UG (haftungsbeschränkt) (in Gründung)</p>
                            <p>Alpenstrasse 18</p>
                            <p>81541 München</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer