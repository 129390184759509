import React, { useEffect } from 'react'
import HomeSec from '../component/HomeSec'
import Header from './../layout/Header';
import Section2 from '../component/Section2';
import Section3 from '../component/Section3';
import Section4 from '../component/Section4';
import Section5 from './../component/Section5';
import Section6 from '../component/Section6';
import { Outlet, useLocation } from 'react-router-dom';
import Footer from '../layout/Footer';
// import Section7 from '../component/Section7';

function Index() {


    const location = useLocation();

    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location.pathname]);

    return (
        <>
            <Header />
            <HomeSec />
            <Section2 />
            <Section3 />
            {/* <Section4 /> */}
            <Section5 />
            <Section6 />  
            <Footer  name={"Unicorso UG (haftungsbeschränkt) (in Gründung)"}/>       
        </>
    )
}

export default Index