import React from 'react'
import { Link } from 'react-router-dom'

function Header() {
    return (
        <>
            <header>
                <div className="container">
                    <div className="logo-main ">
                        <Link to="/">
                            <img src="../image/logo/logo.png" alt="" />
                        </Link>
                    </div>
                </div>
            </header>
            <img className="hero-bg hero-bg-sm z-3" src="../image/png/hero-bg.png" alt="" />
        </>
    )
}

export default Header