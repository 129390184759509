import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/App.css';
import Index from "./pages/Index";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { useEffect } from "react";

function App() {


  return (
    <>
      {/* <Outlet> */}
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </BrowserRouter>
      {/* </Outlet> */}
    </>
  );
}

export default App;
