import React from 'react'

function Angebot() {
    return (
        <>
            <>
                {/* Section 2 start */}
                <section className="section-top-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12 col-md-6 pe-3 pe-lg-5">
                                <img
                                    src="../image/png/angebot.png"
                                    className="angebot-img"
                                    alt=""
                                />
                            </div>
                            <div className="col-12 col-md-6 text-box pt-4 pt-md-0">
                                <h2>Angebot</h2>
                                <p>
                                    Sie können mich buchen für Vorträge zu den Themen Datenschutzrecht
                                    oder Compliance. Ich habe etliche Jahre Erfahrung in dem Bereich und
                                    halte es für wichtig, dass Datenschutz und Compliance gelebt werden.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Section 2 end */}
            </>

        </>
    )
}

export default Angebot