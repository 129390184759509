import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Section6() {
    const responsiveOptions = {
        0: {
            items: 1,
            center: false,
            stagePadding: 15, // Adjust as needed for small screens
        },
        600: {
            items: 1,
            center: false,
            stagePadding: 100, // Adjust as needed for medium screens
        },

        1024: {
            items: 2,
            center: false,
            stagePadding: 20, // Adjust as needed for large screens
        },

        1440: {
            items: 2,
            center: false,
            stagePadding: 200, // Adjust as needed for large screens
        },

        1660: {
            items: 3,
            center: false,
            stagePadding: 200, // Adjust as needed for large screens
        },
    };
    return (
        <>
            {/* Section 6 start */}
            <section className="reviews-main section-top-100 position-relative">
                <div className="container-fluid px-0 px-md-5 pb-3 pb-md-5">
                    <h2 className='mb-4 ps-5'>Reviews</h2>
                    <div className="card reviews-bg-card rounded-4 border-0">
                    </div>
                </div>
                <div className='reviews-card'>
                    <OwlCarousel
                        className='owl-theme pt-0 pt-md-0'
                        loop={true}
                        margin={30}
                        nav={true}
                        dots={false}
                        responsive={responsiveOptions}
                    autoplay
                    autoplayTimeout={2000}
                    >
                        <div className="item">
                            <div className="card reviews-user-main border-0 rounded-4 shadow">
                                <div className="card-body p-3 p-md-5">
                                    <div className='reviews-user d-flex gap-3 align-items-center mb-3'>
                                        <p className='reviews-user-name text-nowrap mb-0'>Ernest Mujkic</p>
                                    </div>
                                    <q>Der Vortrag war ein echtes Aha-Erlebnis für die Schüler der 7. Klasse – informativ, ansprechend und direkt auf ihre Lebenswelt bezogen.</q>
                                </div>
                                <div className="card-footer reviews-user-footer bg-transparent border-0 px-3 px-md-5">
                                    <p>Gymnasiallehrer des Oskar-Von-Miller Gymnasium</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card reviews-user-main border-0 rounded-4 shadow">
                                <div className="card-body p-3 p-md-5">
                                    <q>Man hört Herrn Beisinghoff immer gerne zu, da er alle datenschutzrechtlichen Konzepte mit Anekdoten untermalen kann.</q>
                                </div>
                                <div className="card-footer reviews-user-footer bg-transparent border-0 px-3 px-md-5">
                                    <p>Leiterin einer Steuerabteilung eines großen Konzerns</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card reviews-user-main border-0 rounded-4 shadow">
                                <div className="card-body p-3 p-md-5">
                                    <q>Jetzt check ich's voll mit Instagram und Alexa und so. Muss echt aufpassen, was ich da poste, nicht dass meine Daten überall landen! Ich verkaufe unsere Alexa.</q>
                                </div>
                                <div className="card-footer reviews-user-footer bg-transparent border-0 px-3 px-md-5">
                                    <p>Kind (anonym) nach einem Vortrag</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card reviews-user-main border-0 rounded-4 shadow">
                                <div className="card-body p-3 p-md-5">
                                    <q>Okay, ich habe ein Recht auf Löschung aus der DSGVO. Aber wie kann ich sicher sein, dass Tiktok oder Instagram alle meine Daten gelöscht hat?</q>
                                </div>
                                <div className="card-footer reviews-user-footer bg-transparent border-0 px-3 px-md-5">
                                    <p>Kind (anonym) nach einem Vortrag</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card reviews-user-main border-0 rounded-4 shadow">
                                <div className="card-body p-3 p-md-5">
                                    <q>Meta und Google sind echt überall, oder? Ich dachte, die machen nur coole Apps. Aber jetzt weiß ich, dass ich aufpassen muss, was ich online mache.</q>
                                </div>
                                <div className="card-footer reviews-user-footer bg-transparent border-0 px-3 px-md-5">
                                    <p>Kind (anonym) nach einem Vortrag</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card reviews-user-main border-0 rounded-4 shadow">
                                <div className="card-body p-3 p-md-5">
                                    <div className='reviews-user d-flex gap-3 align-items-center mb-3'>
                                        <p className='reviews-user-name text-nowrap mb-0'>Norbert Ripple</p>
                                    </div>
                                    <q>Niels hat die bemerkenswerte Fähigkeit, hochkomplexe theoretische Konzepte auf eine verständliche und zugängliche Weise zu vermitteln.</q>
                                </div>
                                <div className="card-footer reviews-user-footer bg-transparent border-0 px-3 px-md-5">
                                    <p>Leitender Mitarbeiter einer Risk Abteilung eines großen Konzerns</p>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="card reviews-user-main border-0 rounded-4 shadow">
                                <div className="card-body p-3 p-md-5">
                                    <div className='reviews-user d-flex gap-3 align-items-center mb-3'>
                                        <p className='reviews-user-name text-nowrap mb-0'>Christian Taube</p>
                                    </div>
                                    <q>Niels Beisinghoff gehört zu den Experten im Bereich der Datenschutzrechts-Compliance und hat als mein Kollege bei DataGuard viele große Milliarden-Konzerne beraten. Auch als Syndikusanwalt weiß er gewieft die Waffen der DSGVO zu nutzen.</q>
                                </div>
                                <div className="card-footer reviews-user-footer bg-transparent border-0 px-3 px-md-5">
                                    <p>Head of Cyber Services at Beazley</p>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </section>
            {/* Section 6 end */}
        </>
    )
}

export default Section6