import React from 'react'

function Section5() {
    return (
        <>
            {/* Section 5 start */}
            <section className="uber-main section-top-100">
                <div className="container">
                    <h2 className='mb-4'>Über Mich</h2>
                    <div className='row justify-content-between pt-4 pt-lg-5'>
                        <div className="col-12 d-md-flex align-items-center gap-4 gap-lg-0">
                            <img src="../image/png/image1.png" className='mb-4 mb-md-0 mb-lg-0' alt="" />
                            <div className="card shadow rounded-3 border-0 rechtsanwalt-card">
                                <div className="card-body p-4">
                                    <p className='text-uppercase card-title'>Rechtsanwalt</p>
                                    <p className='card-subtitle'>
                                        Mein Name ist Niels Beisinghoff.
                                        Ich bin Anwalt und Syndikusanwalt im Bereich des Datenschutzrechtes und der der Compliance.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Section 5 end */}
        </>
    )
}

export default Section5